define("liquid-fire/templates/components/liquid-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "szEQipKc",
    "block": "{\"symbols\":[\"container\",\"valueVersion\",\"valueVersion\",\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"containerless\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[4,\"liquid-versions\",null,[[\"value\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\",\"class\"],[[28,\"if\",[[24,[\"inverted\"]],[28,\"if\",[[24,[\"predicate\"]],false,true],null],[28,\"if\",[[24,[\"predicate\"]],true,false],null]],null],[28,\"hash\",null,[[\"helperName\"],[[24,[\"helperName\"]]]]],[24,[\"use\"]],[24,[\"rules\"]],[25,4],[24,[\"class\"]]]],{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[24,[\"containerId\"]],[24,[\"class\"]],[24,[\"growDuration\"]],[24,[\"growPixelsPerSecond\"]],[24,[\"growEasing\"]],[24,[\"shrinkDelay\"]],[24,[\"growDelay\"]],[24,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\"],[[28,\"if\",[[24,[\"inverted\"]],[28,\"if\",[[24,[\"predicate\"]],false,true],null],[28,\"if\",[[24,[\"predicate\"]],true,false],null]],null],[23,1,[]],[28,\"hash\",null,[[\"helperName\"],[[24,[\"helperName\"]]]]],[24,[\"use\"]],[24,[\"rules\"]],[25,4]]],{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[14,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "templates/components/liquid-if.hbs"
    }
  });

  _exports.default = _default;
});