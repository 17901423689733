define("@glimmer/component/index", ["exports", "@glimmer/component/-private/component-manager", "@glimmer/component/-private/component"], function (_exports, _componentManager, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (true) {
    Ember._setComponentManager(function (owner) {
      return new _componentManager.default(owner);
    }, _component.default);
  } else {
    Ember._setComponentManager('glimmer', _component.default);
  }

  var _default = _component.default;
  _exports.default = _default;
});