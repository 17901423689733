define("ember-cli-meta-tags/templates/components/head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rGWfaAlF",
    "block": "{\"symbols\":[\"headTag\"],\"statements\":[[4,\"each\",[[24,[\"headTags\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"head-tag\",null,[[\"headTag\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "templates/components/head-tags.hbs"
    }
  });

  _exports.default = _default;
});