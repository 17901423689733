define("liquid-fire/templates/components/liquid-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ea3kJeKp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"action\",[[23,0,[]],\"ready\"],null]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "templates/components/liquid-sync.hbs"
    }
  });

  _exports.default = _default;
});