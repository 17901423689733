define("liquid-fire/templates/components/liquid-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oFeReoac",
    "block": "{\"symbols\":[\"version\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"versions\"]]],null,{\"statements\":[[4,\"if\",[[28,\"lf-or\",[[24,[\"renderWhenFalse\"]],[23,1,[\"value\"]]],null]],null,{\"statements\":[[4,\"liquid-child\",null,[[\"version\",\"liquidChildDidRender\",\"class\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"childDidRender\"],null],[24,[\"class\"]]]],{\"statements\":[[14,2,[[23,1,[\"value\"]]]]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "templates/components/liquid-versions.hbs"
    }
  });

  _exports.default = _default;
});