define("ember-modal-dialog/templates/components/liquid-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIvw2I33",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasOverlay\"]]],null,{\"statements\":[[4,\"liquid-wormhole\",null,[[\"stack\",\"class\"],[\"modal-overlay\",\"liquid-dialog-container\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[22,\"overlayClassNamesString\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onClickOverlay\"]]],null]],[10,\"tabindex\",\"-1\"],[10,\"data-emd-overlay\",\"\"],[8],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"liquid-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\",\"stack\",\"value\"],[[24,[\"containerClassNamesString\"]],[24,[\"tetherTarget\"]],[24,[\"attachment\"]],[24,[\"targetAttachment\"]],[24,[\"targetModifier\"]],[24,[\"tetherClassPrefix\"]],[24,[\"offset\"]],[24,[\"targetOffset\"]],[24,[\"constraints\"]],[24,[\"stack\"]],[24,[\"value\"]]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "templates/components/liquid-tether-dialog.hbs"
    }
  });

  _exports.default = _default;
});