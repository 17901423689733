define("liquid-fire/templates/components/liquid-spacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "glO0hWOQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"liquid-measured\",null,[[\"didMeasure\"],[[28,\"action\",[[23,0,[]],[23,0,[\"sizeChanged\"]]],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "templates/components/liquid-spacer.hbs"
    }
  });

  _exports.default = _default;
});